import { required } from "vuelidate/lib/validators";
import { NumberFormater } from "@/_helpers/funciones";

export default (PackagingWeight, PackagingQuantity, ValidateHold, FgTotalWeight, FgTotalQuantity) => {
  let ValidateHoldHatch = ValidateHold ? { required } : {};
  return {
    VesselUbicationId: { required },
    VesselHoldId: ValidateHoldHatch,
    VesselHoldHatchCId: ValidateHoldHatch,
    StowagePlanningBillOfLadingId: { required },
    StowagePlanningBillOfLadingPackagingId:{ required },
    WeightAsigned: { 
      required(Value){
        return NumberFormater.setNum(Value) > 0
      }, 
      notAvailablePackaging(Value) {
        return NumberFormater.setNum(Value) <= Number.parseFloat(PackagingWeight ?? 0).toFixed(2)
      },
      ValidateTotalPackaging(value) {
        if (FgTotalQuantity) {
          return Number.parseFloat(value.replace(".", "").replace(",", ".")) == Number.parseFloat(PackagingWeight).toFixed(2);
        }else{
          return true;
        }
      }
    },
    QuantityAsigned: {
      required(Value){
        return  NumberFormater.setNum(Value) > 0
      }, 
      maxValue(Value){ 
        return  NumberFormater.setNum(Value) <= (PackagingQuantity ?? 0)
      },
      ValidateTotalPackaging(value) {
        if (FgTotalWeight) {
          return Number.parseFloat(value.replace(".", "").replace(",", ".")) == Number.parseFloat(PackagingQuantity).toFixed(2);
        }else{
          return true;
        }
      }
    }
  }
}